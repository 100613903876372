<template>
	<div class="asidePairs" data-debugName="asidePairs">		
		<div 	class="asidePairs__pair" 
				v-for="(pair, index) in $root.getTranslatedAcfValue( post.acf, 'sidebarMetas')" 
				:key="index">
			<div class="asidePairs__pairTitle font font--sizeSmall font--uppercase">
				{{pair.title}}							
			</div>
			<div class="asidePairs__pairText font" :class="pairTextFontSizeClass" v-html="pair.text"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'asidePairs',
		components: {},			
		props: {
			post: Object,
		},
		data() {
			return {}
		},
		computed: {
			pairTextFontSizeClass() {
				let sizeClass = 'font--sizeDefault'				
				// 2019-05-06  dieser sonderfall ist wieder rausgefolgen,
				// damit würde es auch eine einfach klasse tun, aber wer weiß wie sich das 
				// noch entwickelt. ich lasse es mal lieber drinne.
				//if( this.$route.name=='TeamView') sizeClass = 'font--sizeSmall'					
				return sizeClass
			}
		},
		methods: {},
		mounted() {},
	}
</script>

<style lang="less" XXXscoped>
	.asidePairs {
		.asidePairs__pair {
			padding-bottom: 1em;
		}
		.asidePairs__pairTitle {
			padding-bottom: 0.2em;
		}
		.asidePairs__pairText {}
		// 2019-06-03	verhindert seitliche scrollbalken, wenn z.b. eine lange mail adresse in der sidebar steht
		// 				dafür war es auch notwendig das scoped attribute zu entfernen
		.asidePairs__pairText > * {	
			overflow: hidden;
			text-overflow: ellipsis;
			//background-color: red;
		}
	}
</style>
