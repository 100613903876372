<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>			
		
		<div class="border border--bottom row" v-if="$root.isSmallDevice">
			<div class="viewTitle vSpace vSpace--default hSpace hSpace--big col">
				<h2 v-html="$root.getTranslatedValue('Publikationen')"></h2>
			</div>
		</div>			
		
		<div class="publicationPosts">		
			
			<div class="publicationPosts__post toggleContent" v-for="post in posts" :id="post.slug" :key="post.id">
				
				<h3 class="border border--bottom row">
					<div class="hSpace hSpace--big hSpace--notRight vSpace vSpace--default col col--11of12">
						<span class="publicationPosts__title font font--sizeBig">
							{{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}
						</span>
						<span class="publicationPosts__rubrik font font--sizeSmall font--uppercase">
							{{$root.getTranslatedAcfValue( post.acf, 'rubrik')}}
						</span>
					</div>
					<div class="hSpace hSpace--big hSpace--notLeft  vSpace vSpace--default col col--1of12">
						<a class="toggleContent__btn" :class="{'toggleContent__btn--isActive' : !post.isFolded}" @click="toggleContent( post )"></a>
					</div>
				</h3>
				
				<div class="noFlexGrow border border--bottom row" :class="{'toggleContent__content' : post.isFolded }">
					
					<div class="col col--3of12 col--sm-12of12" :class="{'border border--right' : !$root.isSmallDevice}">
						<!--
						<span v-for="image in post.acf.images" :key="image.id">{{image.id}}<br /></span>
						{{post.acf.images.length}}
						-->
						
						<template v-if="post && post.acf.images && post.acf.images.length">						
							<MhSlider class="publicationPosts__imageSlider">
								<div class="publicationPosts__slide" v-for="(slide, index) in post.acf.images" :key="index">
									<MhImage 
										class="publicationPosts__slideImage"
										:imageObject="slide" 
										backgroundPosition="top"
										mode="img">
									</MhImage>	
								</div>
							</MhSlider>			
						</template>
									
						<!--
						<MhImage :imageObject="post.acf.image"></MhImage>
						-->
					</div>
					
					<div class="vSpace vSpace--default hSpace hSpace--medium col col--6of12 col--sm-12of12" v-html="$root.getTranslatedAcfValue( post.acf, 'text')" :class="{'border border--right' : !$root.isSmallDevice}">
					</div>
					<div class="vSpace vSpace--default hSpace hSpace--medium col col--3of12 col--sm-12of12">
						<AsidePairs :post="post"></AsidePairs>
					</div>
				</div>
				
			</div>
			
		</div>
		
	</MhView>
</template>

<script>
	// @ is an alias to /src
	import MhView from '@/components/MhView/MhView.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhSlider from '@/components/MhSlider/MhSlider.vue'
	import AsidePairs from '@/components/AsidePairs.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'publicationsView',
		components: {
			MhView,		
			MhImage,		
			MhSlider,		
			AsidePairs,
			MobilePageTitle,
		},
		mixins: [ RestHandler ],
		data() {
			return {
				isLoading: true,
				posts: [],
			}
		},
		methods: {
			toggleContent ( post ) {
				post.isFolded = !post.isFolded
			},
		},
		mounted() {
			
			// fetch publicationPosts
			/*
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/wp/v2/publicationPosts',
				params : {
					per_page : 99,
				},
				callback : (response) => {
					this.posts = response.data
					this.isLoading = false
					
					// add new prop to handle post folding on mobile
					this._.map(this.posts, (post) => {
						this.$set( post, 'isFolded', true )
					});
					
					// wenn man von der suche kommt ist der slug mit in der url,
					// und zu dem soll gleich gescrollt werden					
					if( this.$route.params.postSlug ){
						setTimeout( () => {
							const element = document.getElementById( this.$route.params.postSlug );
							element.scrollIntoView( {block: "start", behavior: "smooth"} )
						}, 150)
					}					
				},
			})
			*/
			
			// fetch all teamPosts
			this.restHandler__fetchAllPaginatedPosts({
				action : 'GET',				
				route : '/wp-json/mh/v1/posts',
				params : {
					postType : 'publication_post',
					perPage : 2,
				},
				callback : (results) => {
					this.posts = results
					this.isLoading = false
					
					// add new prop to handle post folding on mobile
					this._.map(this.posts, (post) => {
						this.$set( post, 'isFolded', true )
					});
					
					// wenn man von der suche kommt ist der slug mit in der url,
					// und zu dem soll gleich gescrollt werden					
					if( this.$route.params.postSlug ){
						setTimeout( () => {
							const element = document.getElementById( this.$route.params.postSlug );
							element.scrollIntoView( {block: "start", behavior: "smooth"} )
						}, 150)
					}	
				}				
			})							

		},
	}
</script>

<style lang="less">
	@import "../less/includes/grid.include.less";
	
	.publicationPosts {
		&__title {
			padding-right: 1em;		
		}
		
		&__imageSlider {
			[showborders1] & { background-color: fade(red, 20); }
			flex-grow: 1;
			overflow: hidden;		
		}
		&__slide {
			[showborders1] & { background-color: fade(red, 20); }
			position: relative;
			display: flex;
		}
		&__slideImage {
			flex-grow: 1;
		}
		&__slideBu {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 10;
			flex-grow: 0;
			flex-shrink: 0;
			padding-bottom: 1em;
			
			span {
				display: block;
			}
		}
		.slider-pagination {
			display: none;
		}
	}
	
	@media @mediaQuery__sm {
		@slider__bodyHeight: 100vw;
		@slider__footerHeight: 3em;
		
		.publicationPosts__imageSlider {
			height: calc(@slider__bodyHeight + @slider__footerHeight);
			flex-grow: 0;
		}
		.publicationPosts__slideBu {
			display: none;
		}
		.publicationPosts .slider-wrap {
			height: @slider__bodyHeight !important;			
		}
		
		.mhSlider__sliderBtn {
			top: auto;
			height: @slider__footerHeight;
			width: @slider__footerHeight;
			padding: 0em;
			
			background-size: 25%;
			background-position: right 1em center;
		}
		.slider-pagination {
			position: absolute;
			left: 0; right: 0;
			bottom: 0 !important;
			height: @slider__footerHeight;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 0em;
		}
		.slider-pagination-bullet.active {
			background-color: black !important;
		}
		.mhSlider--atEnd.mhSlider--atStart { // remove swipe if there is only one slide
			pointer-events: none;
		}
		.mhSlider--atEnd.mhSlider--atStart .slider-pagination { // hide dot if there is only one slide
			display: none !important;
		}
		
		.mhImage__image {
			padding-bottom: 0 !important;
			height: @slider__bodyHeight !important;
			background-size: contain !important;  
		}		
	
	}	
</style>
