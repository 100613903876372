<template>
	<div class="mhSlider" :class="classValue">
		
		<Slider
			ref="Slider"
			direction="horizontal"
			:mousewheel-control="false"
			:performance-mode="true"
			:pagination-visible="true"
			:pagination-clickable="true"
			:loop="false"
			:speed="500"
			@slide-change-start="onSlideChangeStart"
			@slide-change-end="onSlideChangeEnd">
				<slot></slot>
		</Slider>
		
		<span class="mhSlider__sliderBtn mhSlider__sliderBtn--prev" @click="$refs.Slider.prev()"></span>
		<span class="mhSlider__sliderBtn mhSlider__sliderBtn--next" @click="$refs.Slider.next()"></span>
		
		<div class="mhSlider__debug" v-if="debug">
			totalPages: {{totalPages}}<br />
			currentPage: {{currentPage}}<br />
		</div>

	</div>
</template>

<script>
	import Slider from 'vue-plain-slider'

	export default {
		name: 'MhSlider',
		components: {
			Slider,
		},
		props: {
			type: [String],
		},
		data() {
			return {
				debug: false,
				totalPages : null,
				currentPage : null,
			}
		},
		computed: {
			classValue() {
				let classes = []
				
				if(this.totalPages == this.currentPage) classes.push('mhSlider--atEnd')
				if(1 == this.currentPage) classes.push('mhSlider--atStart')
				
				return classes
			}
		},
		methods: {
			onSlideChangeStart( pageNumber, el ) {
				this.totalPages = this.$refs.Slider.$data.slideEls.length
				this.currentPage = pageNumber
			},
			onSlideChangeEnd( pageNumber, el ) {				
				this.$emit('onAfterSlideChanged', pageNumber)
			},
		},
		mounted() {
			this.totalPages = this.$refs.Slider.$data.slideEls.length
			this.currentPage = 1
			
			this.$emit('onAfterSlideChanged', 1)
		},
	}
</script>

<style lang="less">
	.mhSlider {
		position: relative;
		display: flex;	
	}
	.mhSlider__wrap {}
	.mhSlider__slide {
		.mhImage__image {
			background-position-x: right;
		}
	}
	.mhSlider__sliderBtn {	
		//background-color: fade(green, 10);
		position: absolute;
		top: 0; bottom: 0;
		padding: 2em;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		
		&--prev { 
			left: 0; 
			background-image: url('@/../../../assets/imgs/sliderArrow.svg');
			background-repeat: no-repeat;
			background-position: center;
			transform: rotate(180deg);
		}
		&--next { 
			right: 0; 
			background-image: url('@/../../../assets/imgs/sliderArrow.svg');
			background-repeat: no-repeat;
			background-position: center;
		}
		
		.mhSlider--atStart &--prev { display: none; }
		.mhSlider--atEnd &--next { display: none; }
	}
	.mhSlider__debug {
		background-color: fade(yellow, 75);
		position: absolute;
		left: 50%;
		top: 50%;
		padding: 0.5em;
	}
	
	.slider {
		flex-grow: 1;
		
		&-wrap {
			.mhSlider__wrap;
		}
		&-wrap > * {
			.mhSlider__slide;
		}
	}
</style>
